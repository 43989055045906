import React from "react";
import HubspotContactForm from "../pages/courses/hubspot/HubspotContactForm";
const Contact = () => {
  return (
    <div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="kipso-icon-placeholder"></i>
                </div>
                <h2 className="contact-info-one__subtitle">Mumbai</h2>
                <h2 className="contact-info-one__title">Ms Neeti Nagarkar</h2>
                <p className="contact-info-one__text">
                  61/1476, MIG Adarsh Nagar, <br />
                  Near Century Bazar, Worli , Mumbai 400 030
                </p>
                <a href="tel:+91 98201 50447" className="my-2">
                  +91 98201 50447
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="kipso-icon-placeholder"></i>
                </div>
                <h2 className="contact-info-one__subtitle">Pune</h2>
                <h2 className="contact-info-one__title">Ms Anagha Bahulikar</h2>
                <p className="contact-info-one__text">
                  201 Chintamani Residency, <br />
                  Behind Maratha Mandir, Bavdhan, Pune 411 021
                </p>
                <a href="tel:+91 7058821773" className="my-2">
                  +91 7058821773
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-one">
        <div className="container">
          <h2 className="contact-one__title text-center">
            Get in touch <br />
            with us
          </h2>
          <HubspotContactForm/>
          <div className="result text-center"></div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
